import v4 from 'uuid/v4';
import {config} from "../Constants";

export const getURI = (params?: any, store: boolean = true, forceNew: boolean = false): string => {
    console.group('getURI');
    // debugger;
    // console.log('params', params);
    // console.log('window.location.pathname', window.location.pathname);
    // console.log('QuestionnaireLanding', localStorage.getItem('QuestionnaireLanding'))
    if (!params.questionnaireId) {
        params.questionnaireId = getDefaultQuestionnaire(params);
    }

    let QuestionnaireStatus: any = JSON.parse(localStorage.getItem('QuestionnaireStatus') || '{}');
    if (QuestionnaireStatus && !QuestionnaireStatus.latest || !QuestionnaireStatus.questionnaires || !QuestionnaireStatus.top) {
        if (!QuestionnaireStatus.latest) {
            QuestionnaireStatus.latest = {};

        }
        if (!QuestionnaireStatus.questionnaires) {
            QuestionnaireStatus.questionnaires = {};

        }
        if (!QuestionnaireStatus.top) {
            QuestionnaireStatus.top = [];

        }
    }
    // console.log('Initial QuestionnaireStatus', QuestionnaireStatus)

    try {
        if (typeof QuestionnaireStatus.questionnaires === 'string') {
            QuestionnaireStatus.questionnaires = JSON.parse(QuestionnaireStatus.questionnaires || '{}');
        }
    } catch (e) {
        console.error(e)
        QuestionnaireStatus.questionnaires = {};
    }
    try {
        if (typeof QuestionnaireStatus.latest === 'string') {
            QuestionnaireStatus.latest = JSON.parse(QuestionnaireStatus.latest || '{}');
        }
    } catch (e) {
        console.error(e)
        QuestionnaireStatus.latest = {};
    }

    if (!QuestionnaireStatus.top) {
        QuestionnaireStatus.top = []
    }

    try {
        if (typeof QuestionnaireStatus.top === 'string') {
            QuestionnaireStatus.top = JSON.parse(QuestionnaireStatus.top || '[]');
        }
    } catch (e) {
        console.error(e)
        QuestionnaireStatus.top = [];
    }
    const UUID: string = forceNew ? generateUUID() : params.questionnaire_completion_uuid || QuestionnaireStatus.latest.QUUID || generateUUID();
    QuestionnaireStatus.top.push(UUID)
    // console.log('QuestionnaireStatus.questionnaires', QuestionnaireStatus.questionnaires)
    if (typeof QuestionnaireStatus.questionnaires[UUID] === 'undefined') {
        QuestionnaireStatus.questionnaires[UUID] = {
            URI: `${params.client ? '/' + params.client : ''}/q/${params.questionnaireId}/quuid/${UUID}`,
            questionnaire_id: params.questionnaireId,
            QUUID: UUID,
            completed: false,
            started: Date.now(),
        }
    }

    QuestionnaireStatus.latest = QuestionnaireStatus.questionnaires[UUID];

    if (QuestionnaireStatus.top.length >= 10) {
        delete QuestionnaireStatus.questionnaires[QuestionnaireStatus.top[0].QUUID];
        QuestionnaireStatus.top.shift();
    }

    if (store) {
        // console.log('Saved QuestionnaireStatus', QuestionnaireStatus)
        localStorage.setItem('QuestionnaireStatus', JSON.stringify(QuestionnaireStatus));
    }

    console.groupEnd();
    return QuestionnaireStatus.latest.URI;
}


export const generateUUID = (): string => {
    return v4();
}

export const getDefaultQuestionnaire = (params?: any): number => {
    if (params.questionnaireId) {
        return params.questionnaireId;
    } else {
        return config.DEFAULT_QUESTIONNAIRE_ID
    }
}