import React from "react";
import {useHistory} from "react-router-dom";
import {Auth0Provider} from "@auth0/auth0-react";
import 'react-native-get-random-values';

const Auth0ProviderWithHistory = ({children}) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN || 'auth.wchsolutions.co.za';
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
    // console.log('domain');
    // console.log(domain);

    const history = useHistory();

    const onRedirectCallback = (appState) => {
        // console.log('onRedirectCallback')
        // console.log(appState?.returnTo)
        // console.log(window.location.pathname)
        if (localStorage.getItem('QuestionnaireLanding')) {
            if (!appState) {
                appState = {
                    returnTo: localStorage.getItem('QuestionnaireLanding'),
                }
            } else {
                appState.returnTo = localStorage.getItem('QuestionnaireLanding');
            }
        }
        if (appState) {
            history.push(appState?.returnTo || window.location.pathname);
        } else {
            // console.log(localStorage.getItem('QuestionnaireStatus'))
            let QuestionnaireStatus = JSON.parse(localStorage.getItem('QuestionnaireStatus') || '{}');
            if (QuestionnaireStatus && QuestionnaireStatus.latest) {
                history.push(QuestionnaireStatus.latest);
            } else {
                history.push(appState?.returnTo || window.location.pathname);
            }
        }
    };
    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin + '/?callback'}
            onRedirectCallback={onRedirectCallback}
            // audience={audience}
            state="test"
            scope="app_metadata"
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;

