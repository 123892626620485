export interface Config {
    url: {
        API_URL: string,
        API_URI_START: string
    };
    DEFAULT_QUESTIONNAIRE_ID: number
};

const envVariables: Config = {
    url: {
        // @ts-ignore
        API_URL: 'https://api.basenji.wchsolutions.co.za',
        API_URI_START: "/popi-questionnaire"
    },
    DEFAULT_QUESTIONNAIRE_ID: 4
};

// export const config: Config = process.env.REACT_APP_ENV === 'local' ? dev : prod;
export const config: Config = envVariables;
