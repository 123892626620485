import React, {lazy, Suspense} from 'react';

const LazyImpactAssessment = lazy(() => import('./ImpactAssessment'));

// const ImpactAssessment = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => {
const ImpactAssessment = (props) => {
    return <Suspense fallback={null}>
        <LazyImpactAssessment {...props} />
    </Suspense>
}

export default ImpactAssessment;
