import React, {useEffect} from 'react';
import LazyQuestionnaire from './components/Questionnaire/Questionnaire.lazy'
import LazyImpactAssessment from './components/ImpactAssessment/ImpactAssessment.lazy.js'
import './App.scss';
import {Col, Container, Row} from 'react-bootstrap';
import Navbar from 'react-bootstrap/esm/Navbar';
import {
    BrowserRouter as Router,
    Link,
    Switch,
    Route,
    Redirect,
    match, BrowserRouter, useParams
} from 'react-router-dom'

import {createBrowserHistory} from 'history';
import {useAuth0} from "@auth0/auth0-react";

import 'react-native-get-random-values';

import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import {toast} from "react-toastify";
import {config} from "./Constants";
import {getDefaultQuestionnaire, getURI} from "./library/URI";

export const history = createBrowserHistory();

// console.log(match);

function getLatest() {
    // TODO: We should use this to get the latest questionnaire for the current user.

    const QuestionnaireStatus: any = JSON.parse(localStorage.getItem('QuestionnaireStatus') || '{}');
    if (QuestionnaireStatus.latest) {
        return QuestionnaireStatus.latest;
    }
    return null;
}

function getAllVisitedQuestionnaires(): any[] {
    console.group('getAllVisitedQuestionnaires');
    const QuestionnaireStatus: any = JSON.parse(localStorage.getItem('QuestionnaireStatus') || '{}');
    console.log(QuestionnaireStatus.questionnaires);

    try {
        if (typeof QuestionnaireStatus.questionnaires === 'string') {
            QuestionnaireStatus.questionnaires = JSON.parse(QuestionnaireStatus.questionnaires || '{}');
        }
    } catch (e) {
        console.error(e)
        QuestionnaireStatus.questionnaires = {};
    }
    console.log(QuestionnaireStatus);
    if (QuestionnaireStatus.questionnaires) {
        console.log(QuestionnaireStatus.questionnaires);
        console.log(Object.values(QuestionnaireStatus.questionnaires));
    }
    console.groupEnd();
    if (QuestionnaireStatus && (QuestionnaireStatus.questionnaires || QuestionnaireStatus.latest)) {
        if (Object.values(QuestionnaireStatus.questionnaires).length > 1) {
            return Object.values(QuestionnaireStatus.questionnaires);
        } else {
            return [QuestionnaireStatus.latest];
        }
    } else {
        return [];
    }
}


function App() {
    const {isLoading} = useAuth0();
    // if (isLoading) {
    //     return <div>Loading... ...</div>
    // }

    return (
        <>
            {/*HERE ON ALL PAGES*/}
            {/*@ts-ignore*/}
            <Router history={history}>
            <Auth0ProviderWithHistory>
                {
                    isLoading ? <Switch>
                            <Route path="/OldHome">
                                <OldHome/>
                            </Route>
                            <Route path="/users">
                                <Users/>
                            </Route>
                            <Route path="/401" component={Unauthorized}/>
                            <Route path="/popup-error" component={PopupError}/>
                            <Route path="/error" component={Error}/>
                            <Route path="/403" component={Forbidden}/>
                            <Route path="/404" component={NotFound}/>
                            <Route path="/400" component={RequestError}/>
                            <Route path="/500" component={ServerError}/>
                            <Route
                                exact
                                path="/:client?/q/:questionnaireId([0-9]*)"
                                render={({match}) => (
                                    <Redirect
                                        to={getURI(match.params)}/>
                                )}
                            />
                            <Route
                                exact
                                path="/:client?/q/:questionnaireId([0-9]*)/new"
                                render={({match}) => (
                                    <Redirect
                                        to={getURI(match.params, true, true)}/>
                                )}
                            />
                            <Route path="/:client?/q/:questionnaireId([0-9]*)/:questionnaire_completion_id([0-9]*)"
                                   render={({match}) => {
                                       getURI(match.params);
                                       return (
                                           <LazyQuestionnaire/>
                                       )
                                   }}
                            />
                            <Route path="/:client?/q/:questionnaireId([0-9]*)/qcid/:questionnaire_completion_id([0-9]*)"
                                   render={({match}) => {
                                       getURI(match.params);
                                       return (
                                           <LazyQuestionnaire/>
                                       )
                                   }}
                            />
                            <Route
                                path="/:client?/q/:questionnaireId([0-9]*)/quuid/:questionnaire_completion_uuid/:goToResults?"
                                render={({match}) => {
                                    getURI(match.params);
                                    return (
                                        <LazyQuestionnaire/>
                                    )
                                }}
                            />
                            <Route
                                path="/:client?/impact/ia_uuid/:impact_assessment_uuid"
                                render={({match}) => {
                                    getURI(match.params);
                                    return (
                                        <LazyImpactAssessment/>
                                    )
                                }}
                            />
                            <Route
                                exact
                                path="/:client"
                                render={({match}) => (
                                    <Redirect
                                        to={getURI(match.params, true, true)}/>
                                )}
                            />
                            <Route path="/">
                                <Home/>
                            </Route>
                        </Switch> :
                        'Loading ...'
                }


            </Auth0ProviderWithHistory>
        </Router>
        </>

    )

}

function Admin() {
    return <h2>Admin Area</h2>;
}

function RequestError() {
    return <h2>Request Error</h2>;
}

function ServerError() {
    return <h2>Server Error</h2>;
}

function Forbidden() {
    return <h2>Forbidden</h2>;
}

function NotFound() {
    return <h2>Not Found</h2>;
}

function Unauthorized() {
    return <h2>Unauthorized</h2>;
}

function PopupError() {
    const link = localStorage.getItem('QuestionnaireLanding') ?
        '/' + localStorage.getItem('QuestionnaireLanding')
        : '/';
    return <div><h2>Popup Error</h2>
        <Link to={link}>Once popups are allowed. Click here to go back.</Link>
    </div>;
}

function Error() {
    return <div>
        <h2>Error</h2>
    </div>;
}

function getLoc() {
    alert(window.location.href);
}

const getLinks = () => {
    let items = getAllVisitedQuestionnaires();
    // console.log(items)
    if (items.length === 0) {
        return <Link to={`/q/${config.DEFAULT_QUESTIONNAIRE_ID}`}>Start a New Privacy and Protection of Personal
            Information Self Assessment</Link>
    } else if (items.length === 1) {
        return <Link to={items[0].URI}>Continue New Privacy and Protection of Personal Information Self
            Assessment</Link>
    } else {
        let returnLinks: any[] = [];

        const latest = getLatest();
        if (latest) {
            returnLinks.push(<Link to={latest.URI}>Continue Latest Privacy and Protection of Personal Information Self
                Assessment</Link>)
            returnLinks.push(<br/>)
        }
        returnLinks.push(<Link to={`/q/${config.DEFAULT_QUESTIONNAIRE_ID}/new`}>Start a new Privacy and Protection of
            Personal Information Self
            Assessment</Link>)
        returnLinks.push(<br/>)
        items.forEach(item => {
            if (item.completed) {
                returnLinks.push(<Link to={item.URI}>View completed Privacy and Protection of Personal Information Self
                    Assessment {item.started ? ' started at ' + (new Date(item.started)).toLocaleDateString() : ''} with
                    ID {item.QUUID}</Link>)
            } else {
                returnLinks.push(<Link to={item.URI}>Continue Privacy and Protection of Personal Information Self
                    Assessment {item.started ? ' started at ' + (new Date(item.started)).toLocaleDateString() : ''} with
                    ID {item.QUUID}</Link>)
            }
            returnLinks.push(<br/>)
        })
        return returnLinks;
    }
}


function OldHome() {
    const {user, isAuthenticated, isLoading, logout, loginWithRedirect} = useAuth0();
    if (isLoading) {
        return <div>Loading ...</div>;
    }
    if (isAuthenticated) {
        return (
            <div>
                <BrowserRouter forceRefresh={true}> </BrowserRouter>
                <img src={user.picture} alt={user.name}/>
                <h2>{user.name}</h2>
                <p>{user.email}</p>
                <button onClick={() => logout({returnTo: window.location.origin})}>
                    Log Out
                </button>
                <br/>
                {getLinks()}
            </div>
        )
    } else {
        // loginWithRedirect();
        // return ('<div>login to continue</div>')
        return (
            <div>
                <h2>Not logged in</h2>
                {/*<button onClick={() => getLoc()}>GET</button>*/}
                <button onClick={() => loginWithRedirect()}>Log In to Continue</button>
            </div>
        )
    }

}

function Home() {
    // ping('home');
    const {user, isAuthenticated, isLoading, loginWithRedirect, logout} = useAuth0();
    let {
        // @ts-ignore
        client,
        // @ts-ignore
        questionnaireId,
        // @ts-ignore
        questionnaire_completion_id,
        // @ts-ignore
        questionnaire_completion_uuid,
        // @ts-ignore
        goToResults
    } = useParams();


    // if ()

    if (isLoading) {
        return <div>Loading ...</div>;
    }
    if (isAuthenticated) {
        // We need to get the latest questionnaire, and redirect the user to that questionnaire.
        // If there are multiple questionnaires, they should be able to choose which questionnaire to go to.


        if (localStorage.getItem('QuestionnaireLanding')) {
            const latest = localStorage.getItem('QuestionnaireLanding');
            localStorage.removeItem('QuestionnaireLanding');
            return (
                <Redirect
                    to={latest || ''}/>
            )
        }
        return (
            <div>
                <a href={`${client ? '/' + client : ''}/q/${getDefaultQuestionnaire(
                    {
                        questionnaireId: questionnaireId || config.DEFAULT_QUESTIONNAIRE_ID,
                        client,
                        questionnaire_completion_id,
                        questionnaire_completion_uuid,
                    }
                )}`}> if the page does not
                    refresh, please click here to
                    continue to the self assessment</a>
                <Redirect to={`${client ? '/' + client : ''}/q/${getDefaultQuestionnaire(
                    {
                        questionnaireId: questionnaireId || config.DEFAULT_QUESTIONNAIRE_ID,
                        client,
                        questionnaire_completion_id,
                        questionnaire_completion_uuid,
                    }
                )}`}/>
            </div>
        )
    } else {
        // console.log('setting QuestionnaireLanding', window.location.pathname)
        localStorage.setItem('QuestionnaireLanding', window.location.pathname);
        // console.log('getting QuestionnaireLanding', localStorage.getItem('QuestionnaireLanding'))
        loginWithRedirect();
        // return ('<div>login to continue</div>')
        return (
            <div>
                {/*<h2>Not logged in</h2>*/}
                {/*<button onClick={() => getLoc()}>GET</button>*/}
                <button onClick={() => loginWithRedirect()}>Log In to Continue</button>
            </div>
        )
    }

}

function Users() {
    return <h2>Users</h2>;
}

export default App;
