import React, {lazy, Suspense} from 'react';

const LazyQuestionnaire = lazy(() => import('./Questionnaire'));

export interface QuestionnaireRouteParams {
    questionnaireId?: string | undefined;
    questionnaire_uuid?: string | undefined;
}

const Questionnaire = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => {
        // if (!props.match.params.questionnaire_uuid) {
        //     return <Redirect to="/q/" + props.match.params.questionnaireId + "/" + props.match.params.questionnaire_uuid />
        // }

        return <Suspense fallback={null}>
            <LazyQuestionnaire {...props} />
        </Suspense>
}

export default Questionnaire;
